import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen } from 'src/components/loading-screen'
import { paths } from '../paths'
import { RequiredPerm } from '../permissions'
// ----------------------------------------------------------------------

// OVERVIEW
const GrowSocialPage = lazy(() => import('src/pages/grow-social/grow-social'))

// ----------------------------------------------------------------------

export const growSocialRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths?.growSocial.root,
        element: (
        <CheckPermission permissionRequired={RequiredPerm?.GrowSocial}>
            <GrowSocialPage />
        </CheckPermission>
        ),
    },
    ],
  },
]
