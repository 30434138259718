import { useEffect, useCallback, useState } from 'react'
// routes
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hook'
import { useDispatch } from 'react-redux'
import { RequiredPerm } from 'src/routes/permissions';
//
import { useLocation, Navigate } from 'react-router-dom'
import { useAuthContext } from '../hooks'
import {
  fetchAreas,
  fetchChannels,
  fetchCities,
  fetchCuisines,
  fetchLocations,
} from '../../utils/utilityApiConfig'
import {
  setLocations,
  setChannels,
  setBrands,
  setCuisines,
  setAreas,
  setCities,
} from '../../reduxStore/FilterSlice/FilterSlice'

// ----------------------------------------------------------------------

// const loginPaths: Record<string, string> = {
//   jwt: paths.auth.jwt.login,
// };

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function AuthGuard({ children }: Props) {
  const router = useRouter()
  const dispatch = useDispatch()

  const { user, authenticated } = useAuthContext()
  const isGrowIntelEnable = user?.isCustomerSuccessUser || user?.features?.some((perm: string) => RequiredPerm?.GrowIntel?.includes(perm))

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!authenticated) {
      router.replace(paths?.auth?.jwt?.login)
    } else {
      setChecked(true)
    }
  }, [authenticated, router])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          channelsData,
          locationsData,
          citiesData,
          areasData,
          cuisinesData,
        ] = await Promise.all([
          fetchChannels(),
          fetchLocations(),
          fetchCities(isGrowIntelEnable, user?.country_name==='Romania' ? 'glovo' : undefined),
          fetchAreas(isGrowIntelEnable),
          fetchCuisines(isGrowIntelEnable, user?.country_name==='Romania' ? 'glovo' : undefined),
        ])
        dispatch(setLocations(locationsData))
        dispatch(setChannels(channelsData))
        dispatch(setBrands(user?.brands))
        dispatch(setCities(citiesData))
        dispatch(setAreas(areasData))
        dispatch(setCuisines(cuisinesData))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [authenticated, dispatch, isGrowIntelEnable, user])

  if (!checked) {
    return null
  }

  return <>{children}</>
}

interface CheckPermProps {
  children: React.ReactNode
  permissionRequired: string[]
  isGrowIntelEnable?: boolean
}

export function CheckPermission({
  children,
  permissionRequired,
  isGrowIntelEnable=false
}: CheckPermProps) {
  const { user } = useAuthContext()
  const location = useLocation()

  if (
    permissionRequired?.length > 0 &&
    !user?.isCustomerSuccessUser &&
    (!user?.permissions?.some((perm: string) =>
      permissionRequired?.includes(perm),
    )&& !isGrowIntelEnable)
  ) {
    return <Navigate to="/unauthorized" state={{ form: location }} replace />
  }
  return <>{children}</>
}
