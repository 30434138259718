// utils
import { paramCase } from 'src/utils/change-case';


const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/pulse',
  DASHBOARD_OLD: '/dashboard',
  MARKETING: '/campaigns',
  MANAGEMENT: '/management',
  OPERATIONS: '/operations',
  EXPLORE: '/explore',
  GROW_INTEL: '/grow-intel',
  PRODUCT_FRUITS: 'https://mygrowdash.productfruits.help/en',
  GROW_SOCIAL: '/grow-social',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',

  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      reset_password: `${ROOTS.AUTH}/reset-password`
    },
  },

  growSocial: {
    root: ROOTS.GROW_SOCIAL,
  },

  // MARKETING
  marketing: {
    root: ROOTS.MARKETING,
    ads: `${ROOTS.MARKETING}/ads`,
    ads_detail: (id: string) => `${ROOTS.MARKETING}/ads/${id}`,
    campaign_add: `${ROOTS.MARKETING}/ads/campaign/create`,
    area_performance: `${ROOTS.MARKETING}/ads/area-performance`,
    campaign_edit: (id: string) => `${ROOTS.MARKETING}/ads/campaign/edit/${id}`,
    discount: `${ROOTS.MARKETING}/discount`,
    discount_detail: (id: string) => `${ROOTS.MARKETING}/discount/${id}`,
    rbdc_discount_detail: (id: string) => `${ROOTS.MARKETING}/discount/rbdc/${id}`,
    discount_campaign_add: `${ROOTS.MARKETING}/discount/campaign/create`,
    market: `${ROOTS.MARKETING}/market`,
    calendar: `${ROOTS.MARKETING}/calendar`,
  },

  // DASHBOARD
  // INFO! for old routes use DASHBOARD_OLD
  dashboard: {
    root: ROOTS.DASHBOARD,
    root_old: ROOTS.DASHBOARD_OLD,
    dashboard: `${ROOTS.DASHBOARD}/overview`,
    dashboard_old: `${ROOTS.DASHBOARD_OLD}/overview`,
    // grow_intel: `${ROOTS.DASHBOARD}/grow-intel?tab=item_search`,
    growth: `${ROOTS.DASHBOARD}/growth`,
    finance: `${ROOTS.DASHBOARD}/economics`,
    finance_old: `${ROOTS.DASHBOARD_OLD}/finance`,
    overview_details: `${ROOTS.DASHBOARD}/overview/details`,
  },

  // MANAGEMENT
  management: {
    root: ROOTS.MANAGEMENT,
    profile: `${ROOTS.MANAGEMENT}/profile`,
    credit_notes: `${ROOTS.MANAGEMENT}/credit-notes`,
    budget: `${ROOTS.MANAGEMENT}/budget`,
  },
  // MANAGEMENT
  growIntel: {
    root: ROOTS.GROW_INTEL,
    item_search: `${ROOTS.GROW_INTEL}/item_search`,
    menu_suggestion: `${ROOTS.GROW_INTEL}/menu_suggestion`,
    menu_analysis: `${ROOTS.GROW_INTEL}/menu_analysis`,
    competitors: `${ROOTS.GROW_INTEL}/competitors`,
    popular_brands: `${ROOTS.GROW_INTEL}/popular_brands`,
    popular_items: `${ROOTS.GROW_INTEL}/popular_items`,
    market_changes: `${ROOTS.GROW_INTEL}/market_changes`,
    grow_intel_restaurant: `${ROOTS.GROW_INTEL}/restaurant/:id`,
  },
  operations: {
    root: ROOTS.OPERATIONS,
    reviews_and_rating: `${ROOTS.OPERATIONS}/reviews-ratings`,
  },
  explore: {
      growthhub: `${ROOTS?.EXPLORE}/growthhub`,
      growthhub_extended: ROOTS.PRODUCT_FRUITS,
  },
};
